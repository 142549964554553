.Toastify__toast--error {
    border-radius: 4px !important;
    border: 1px solid $danger !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;
    color: $secondary-alt !important;
    .Toastify__progress-bar {
        background: $danger !important;
    }
}
.Toastify__toast--success {
    border-radius: 4px !important;
    border: 1px solid $success !important;
    background: $success-light !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;
    color: $secondary-alt !important;
    .Toastify__progress-bar {
        background: $success !important;
    }
}
.Toastify__toast--info {
    border-radius: 4px !important;
    border: 1px solid $warning !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;
    color: $secondary-alt !important;
    .Toastify__progress-bar {
        background: $warning !important;
    }
}
.Toastify__close-button {
    color: $secondary-alt !important;
    align-self: center !important;
    opacity: 1 !important;

    & > svg {
        height: 22px !important;
        width: 22px !important;
    }
}

:root {
    --toastify-font-family: 'Nunito', sans-serif !important;
}

.Toastify__toast-container {
    width: auto !important;
}

.Toastify__toast {
    max-width: 500px;
    align-items: center;
    > svg {
        width: 24px;
    }
}

.Toastify__toast-body {
    flex: 1 !important;
    padding-right: 24px !important;
}

.Toastify__toast-icon {
    width: auto !important;
}