//
// tables.scss
//

// Custom table components (Custom)

.table {
    th {
        font-weight: $font-weight-bold;
        color: #030E3A;
        // color: $secondary;
        background: $white;
        border-color: $gray-300;
        min-height: 56px;
        &:not(.align-top) {
            vertical-align: middle !important;
        }
        // padding: map-get($spacers, 2);
        padding: 16px 24px;
    }
    td {
        // padding: map-get($spacers, 2);
        padding: 16px 24px;
        min-height: 56px;
        &:not(.align-top) {
            vertical-align: middle !important;
        }
        p{
            margin-bottom: 0px;
        }
        a.underline:hover{
            text-decoration: underline !important;
        }
        &.select-all{
            width: 45px!important;
        }
    }
    tbody tr:hover{
        background-color: white!important;
    }
    tbody tr{
        background-color: white;

        &.selected {
            background-color: $primary-hover !important;
        }
    }
    tbody tr:nth-child(odd) {
        background-color: #fdfdfd;
    }
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }

    .table-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }
}

.table-hover {
    tbody tr:hover{
        background-color: $primary-hover !important;
    }
}

.bg-hover {
    background-color: $primary-hover !important;
}

.table-condensed {
    td {
        padding: map-get($spacers, 1);
    }
    tr {
        background-color: unset !important;
    }
}

.actions-tab > * {
    float: left;
    margin-left: 10px;
}
.topSharedUrls{
    td{
        word-break: break-all;
    }
}
