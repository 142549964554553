//
// reboot.scss
//


// a
p {
  margin-bottom: 8px;
}
.primary-hover{
  background-color: $primary-hover !important;
}
a {
  &:not(.btn-link) {
    text-decoration: none !important;
  }

  &:focus {
    outline: none;
  }
}

// Forms
label{
  margin-bottom: 0px;
}
label[color='danger'] {
  color: $danger;
}
label[color='success'] {
  color: $success;
}

// Buttons
button:focus {
  outline: none;
}

// Icons
.icon-dual {
  color: $gray-600;
  fill: rgba($gray-600, 0.12);
}

@each $color, $value in $theme-colors {
  .icon-dual-#{$color} {
    color: $value;
    fill: rgba($value, 0.16);
  }
}

.icon-xs {
  height: 16px;
  width: 16px;
}

.icon-lg {
  height: 32px;
  width: 32px;
}

.icon-xl {
  height: 48px;
  width: 48px;
}

.icon-xxl {
  height: 72px;
  width: 72px;
}
.link-zindex-1{
  z-index: 1;
  &:hover{
    text-decoration: underline !important;
  }
}