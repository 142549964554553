//
// Threat.scss
//

.engagement-box {
    background: #F8F8F8;
    border-radius: 5px;
    width: 230px;
    height: 100px;
    padding: 25px;
}
.no-labels-header {
    margin-top: -35px;
    z-index: -999
}
.clickable {
    cursor: pointer;
}
.no-cards-display {
    margin-bottom: 16px;
    margin-top: -25px;
}
.content-text {
    font-size: 14px;

    .content-title {
        font-size: 16px;
        font-weight: 700!important;
    }
}
.context-box {
    background: #FBFBFB;
    border: solid #E5E6EB;
    border-width: 0 1px 0 1px;
    min-height: 220px;
}

@media screen and (max-width: 991px) {
    .context-box {
        border-width: 1px 1px 0 1px;
    }
}

.engagement-context {
    .reach-title {
        height: 26px;
        font-size: 17px;
        font-weight: 700;
    }
}

.removed-at-source {
    background: #ffbbcb;
    height: 150px;
    text-align: center;
    align-content: center;
    border-radius: 5px;
    position: relative;
    .text {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .description {
            margin-top: 10px;
            line-height: 20px;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.sub-content {
    font-size: 16px;
}

.bold-card-title {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;

    &.summary {
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.card-content {
    font-size: 16px;
    background: #FDFDFD;
    border-radius: 5px;
    padding: 16px;
}

.unknown-source {
    background: #ffffff;
    height: 100px;
    text-align: center;
    align-content: center;
    border-radius: 5px;
    position: relative;
    .text {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        color: $gray-400;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .description {
            margin-top: 10px;
            line-height: 20px;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
.unknown-location {
    background: #f3f3f3;
    height: 120px;
    // width: 300px;
    text-align: center;
    // align-content: center;
    border-radius: 5px;
    position: relative;
    .text {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        color: $gray-400;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .description {
            margin-top: 10px;
            line-height: 20px;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
.threat-tooltip {
    height: 24px;
    width: 24px;
    font-size: 14px;
    justify-content: center;
    vertical-align: middle;
    padding: 0px 0;
    text-align: center;
    background: $blue;
    color: white;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 0;
}

.threat-title {
    font-size: 20px;
    vertical-align: middle;
    font-weight: 700;
    display: flex;
}

.threat-resolvers {
    display: flex;
    margin-top: 20px;
}

.fbiframe {
    width: 300px;
}

.translate-button {
    padding: 5px 10px 5px 10px;
    font-weight: 500;
    font-size: 16px;
}

//
// REWORK BELOW
//


.threat-item {
    &.odd {
        background-color: #f8f8f8;
    }
    .threat-item-content {
        padding: 16px;
        border-bottom: 1px solid $gray-300;
        .ctype-circle-small {
            height: 9px;
            width: 9px;
            border-radius: 9px;
            margin-right: 7px;
            display: inline-block;
        }
        .ctype {
            font-weight: 600;
        }
        .from {
            color: #707070;
        }
        .content-col {
            flex: 1;
        }
    }
}


.threat-page {
    .min-height {
        min-height: calc(100vh - 132px);
    }
    .right-side-bar {
        position: relative;
        @media screen and (min-width: 768px) {
            border-left: 1px solid #e2e7f1;
        }
    }
    .instagram-media {
        min-width: 0 !important;
    }
}

.multi-tabs {
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 24px;
    h4 {
        font-weight: bold;
        font-size: 18px;
        transition: all 0.25s ease-in-out;
        margin-top: 0;
    }
    > div {
        border-bottom: 2px solid transparent;
    }
    .selected-tab {
        h4, strong{
            color: $primary;
        }
        border-bottom: 2px solid $primary;
    }
    .disabled {
        h4, strong {
            color: $gray-600
        }
    }
}

.bg-gray {
    background-color: $gray-300 !important;
}

.highlight-em em, .highlighted-text {
    background-color: #FFFF00;
    border-radius: 4px;
    font-style: normal;
    padding: 0;
    &.location {
        background-color: #00CBF9;
    }
}
.readMore-button{
    color: $primary;
    margin-left: 5px;
    cursor: pointer;
}

.read-more-text {
    display: inline;
    width: 100%;
}

.entity-color {
    padding: 6px;
}

.align-dropdown-toggle{
    min-width: 10px;
    padding-right: 0;
}
.bg-primary-on-hover:hover {
    background-color: $primary-hover;
}
.url-hover{
    color: $primary;
    :hover{
    color: #004dad;
    }
}

.tooltip-hover :hover{
    color: #004dad;
}
.engagement-container {
        display: grid;
        height: auto; 
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(auto-fit, minmax(0, 1fr)); 
        grid-row-gap: .5em;
        grid-column-gap: 1em;
        margin-top: 16px;
        width: auto;
}
.engagement-cards {
    background-color: white;
    padding: 16px;
    justify-content: center;
    align-items: center;
}
.engagement-cards:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
}

.signal-container{
    min-height: 140px;
}
.high-threat-tag{
    background-color: #FFEDF0;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
}
.low-threat-tag{
    background-color: #FFFAE8;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
}
.no-threat-tag{
    background-color: #E6F1FE;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
}

.manage-signals{
    display: grid;
    grid-template-columns: 50% 50%;
}

.signal-tag{
    border-radius: 8px;
}

.text-no-wrap {
    text-wrap: nowrap;
}
.signal-item{
    background-color: #F8F8F8;
    border-radius: 4px;
}

.top-right{
    position: absolute;
    top: 20px;
    right: 10px;
}

.threat-signal-cards{
    height: 548px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}