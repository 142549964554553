.highcharts-stock-chart {
    .highcharts-scrollbar-track {
        height: 2px;
        transform: translateY(10px);
        fill: #E5E6EB !important;
        stroke-width: 0px !important;
        stroke: #E5E6EB !important;
    }
    .highcharts-scrollbar-thumb {
        height: 2px;
        transform: translateY(10px);
        fill: #006FF9 !important;
        stroke-width: 0px !important;
        stroke: #006FF9 !important;
        border-top: 1px solid #E5E6EB;
    }
    .highcharts-navigator-outline, .highcharts-grid.highcharts-navigator-xaxis, .highcharts-scrollbar-rifles {
        display: none;
    }
    .highcharts-series.highcharts-navigator-series {
        .highcharts-area {
            display: none;
        }
        .highcharts-graph {
            stroke-width: 2px !important;
        }
    }
    .highcharts-navigator-mask-outside {
        fill: rgba(255,255,255,.7) !important;
    }
    .highcharts-legend-item .highcharts-graph {
        stroke-width: 10px;
    }
}
.chartText{
    padding: 15px 25px;
    font-weight: 700;
}