.boolean-search-input {
    border-right: solid;
    border-width: 0.01px;
    min-height: 200px;
    border-color: #E2E7F1;
    max-height: 100%;
    overflow-y: auto;
    width: 75%;
    flex: 2;
    @media (max-width: 992px) {
        width: 100%;
        border-right: none;
    }
}

.settings-noBorder {
    .boolean-search-input {
        border: none;
    }
}

.boolean-search-input-error {
    border: 1px solid $danger;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.boolean-search-error-panel {
    background-color: $white;
}

.boolean-input-validated {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    min-height: 200px;
    height: 100%;
    overflow: auto;
    @media (max-width: 1272px) {
        max-height: calc(100vh - 300px);
    }
    @media (max-width: 992px) {
        justify-content: start;
        flex-direction: column-reverse;
    }
}

.boolean-search-content {
    border: solid;
    border-width: 0.01px;
    border-color: #E2E7F1;
    border-radius: 4px;
}

.boolean-search-explanation {
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 100%;
    min-height: 50px;
    overflow-y: auto;
    @media (max-width:1272px) {
        border-bottom: solid;
        border-width: 0.01px;
        border-color: #E2E7F1;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        min-height: 10vh;
        flex: 1;
    }

    @media (max-width: 992px) {
        border-bottom: solid;
        border-width: 0.01px;
        border-color: #E2E7F1;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }
}

.boolean-search-issues {
    color: $white;
    background-color: $danger;
    border-radius: 20px;
    min-width: 22px;
    min-height: 22px;
    padding: 0 4px;
    flex-direction: column;
    flex-grow: 1;
}

.disabled-input {
    background-color: #e9ecef;
    opacity: 1;
    cursor: not-allowed;
}
.boolean-height-custom{
    max-height: 30vh;
}

.boolean-search-focused{
    border: 1px solid transparent;
    &:active,&:hover,&:focus{
        border: 1px solid $primary;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}
.settings-noBorder{
    .boolean-search-focused{
        &:active,&:hover,&:focus{
            border: none;
        }
    }
}