:root {
    --color-primary: #00C0C7;
    --color-primary-offWhite: #FDFDFD;
}


.BarChart {
    /* background-color: var(--color-primary); */
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: inherit;
        /* // inherit will take in the height of its parent, you could also  */
        /* // use 100% if the parent's height is set. */
    /* width: 4em !important; */
    overflow:hidden;
    margin-top: 10px;
}

.BarData {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PercentText {
    color: var(--color-primary-offWhite);
    font:700 1.25rem sans-serif;
    text-align: center;
}
 span.arrow{
    display: none !important;
}
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"], .bs-tooltip-auto[x-placement^="right"] {
    margin-left: 10px !important;
}
