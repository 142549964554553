//
// nav.scss
//

// Bootstrap override - turn nav tab with border

.nav-tabs {
    border-bottom: none;
    &.progress-step{
        
        border-bottom: 1px solid #e5e6eb;
        .nav-item{
            margin-bottom: 0px;
            a{
                &.nav-link{
                    margin-right: 0px;
                    h3{
                        color: $gray-600;
                    }
                    &.completed{
                        h3{
                            color: $gray-400;
                        }
                    }
                    &.active{
                        border-bottom: none !important;
                            h3{
                            color: $primary;
                            padding-bottom: 8px;
                            margin-bottom: 0px;
                            border-bottom: 2px solid $primary !important;
                            &:hover{
                                color: #025AC7 !important;
                            }
                        }
                        span{
                            color: $gray-400 !important;
                        }
                    }
                    
                }
            }
        }
    }
    .nav-item {
        cursor: pointer;
      
        a{
            &.nav-link{
                color: $gray-400;
                // color: $gray-700;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-left: 0px;
                margin-right: 28.8px;
                &.active{
                    background-color: transparent;
                    color: $primary;
                    h4{
                        color: $primary;
                    }
                    &:hover{
                        color: #025AC7;
                    }
                }
                &.disabled{
                    border-color: transparent !important;
                    color: $gray-600;

                }
            }
        }

    }

    li {
        a {
            border-color: transparent !important;
            padding: 0.625rem 0.2rem;
            margin: 0rem 0.5rem
            // padding: 0.625rem 1.25rem;
        }
        a.active {
            border-bottom: 2px solid $primary !important;
            .nav-link {
                &:hover,
                &:focus,
                &:active {
                    border-bottom: 3px solid $primary;
                    // border-bottom: 3px solid #f62459;
                    color: #ffffff;
                    text-decoration: none;
                    background-color: rgba($menu-item-bg-hover, 0.2);
                    svg {
                        color: $menu-item;
                    }
                }
            }
        }
    }
}
.navbar-brand {
    margin-right: 0;
}
// pills
.nav-tabs,
.nav-pills {
    // text-transform: uppercase;
    letter-spacing: 0.5px;
    > li {
        > a {
            // color: $gray-700;
            font-weight: $font-weight-bold;
        }
    }
}

.nav-pills {
    background: $secondary;
    border-radius: $border-radius;
    padding: 6px 2px;

    .nav-link {
        background-color: rgba($gray-600, 0.2);
        color: #fff;
    }

    .nav-link.active,
    .show > .nav-link {
        color: $secondary;
        background-color: $primary;
        box-shadow: $box-shadow-sm;
    }
}

.navbar-dark {
    .show > .nav-link
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {            
        border-bottom: 3px solid $primary;
        background-color: $table-dark-hover-bg;
    }
}