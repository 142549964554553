//
// helper.scss
//

.mw-0 {
    min-width: 0px !important;
}

.mh-0 {
    min-height: 0px;
}

.maxw-none {
    max-width: none !important;
}

// Font weight help class

.font-weight-semibold {
    font-weight: 600;
}
