.feedbackTable .form-group{
    margin-left: 16px !important;
    .custom-checkbox{
        padding-top: 0px !important;
        .custom-control-input{
            position: absolute;
        }
    }
} 

.set-width{
    width: 10rem !important;
}

.grad1{
    margin-left: 8px;
    margin-right: 8px;
    height: 3px;
    background-image: linear-gradient(to right, #FFC700 , #4367F8);
    width: 500px;
}

.contentTable td {
    vertical-align: top !important;
}

.network-settings{
    .network-node-input{
        width: $display4-size;
    }
    .network-node-bar {
        height: 2px;
    }
}