//
// alerts
// Bootstrap - extended default
//

// @each $color, $value in $theme-colors {
//     .alert-#{$color} {
//       @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), color-yiq(theme-color-level($color, $alert-bg-level)));
//       .close,
//       .alert-link {
//         color: color-yiq(theme-color-level($color, $alert-bg-level));
//       }
//     }
//   }
.alert{
  color: black;
  padding: 16px;
  span[tag='icon']{
    position: relative;
    bottom: 2px;
    // padding-right: 8px;
  }
}
.alert-dismissible .close{
  position: relative;
  top: -12px;
}
.alert-warning {
  background-color: $warning-light;
  border-color: $warning;
}
.alert-danger {
  background-color: rgba(248, 67, 103, 0.1);
  border-color: $danger;
}
.alert-success {
  background-color: rgba(19, 186, 156, 0.1);
  border-color: $success;
}
.alert-primary {
  background-color: rgba(0, 111, 249, 0.15);
  border-color: $primary;
}