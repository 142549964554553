//
// situation-room.scss
//

.go-back-investigations{
    max-width: fit-content;
    text-decoration: none;
    text-align: left;
}

.status-high {
    color: #F62459;
}
.status-medium {
    color: #FFA010;
}
.status-low {
    color: #05A00A;
}

.daterangepicker {
    z-index: 999999999;
}

@function getArrow($rotate) {
    @return "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='-5 -10 45 24' fill='none' style='transform: rotate(#{$rotate}deg);' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 8H1' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 15L1 8L8 1' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 8H29' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22 1L29 8L22 15' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A";
}

.app .react-grid-layout {
    .handle-s {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        cursor: url(getArrow(90)) 15 20, s-resize;
    }
    .handle-e {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        cursor: url(getArrow(0)) 15 25, e-resize;
    }
    .handle-se {
        top: 100%;
        left: 100%;
        transform: translate(-51%, -52%);
        cursor: url(getArrow(45)) 15 25, se-resize;
    }
    .react-resizable-handle {
        position: absolute;
        height: 16px;
        width: 16px;
        background: none;
        &:after {
            display: none;
        }
        svg {
            margin-top: -10px;
            margin-left: -2px;
        }
    }
    .react-grid-placeholder {
        background: $primary;
    }
    .widget-actions {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 99;
    }
}

.overview-spacer {
    height: 30px;
    @media screen and (min-width: 1231px) {
        height: 0px;
    }
}

.content-top-bar {
    min-height: 26px;
    position: relative;
    z-index: 1;
    + hr {
        border-top: 1px dashed #E5E6EB;
    }
}

.filter-item{
    display: inline-flex;
    padding: 4px 6px;
    align-items: center;
    background-color: #E6F1FE;
    color: #030E3A;
    border-radius: 4px;
}
