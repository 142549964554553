.content-page{
    .situation-room-list{
        @media only screen and (min-width: 1681px) {
            max-width: 1680px;
            margin-left: auto !important;
            margin-right: auto !important;
        }
        .display-card{
            ::-webkit-scrollbar {
                height: 10px;
            }
            ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey; 
                border-radius: 10px;
              }
               
              /* Handle */
              ::-webkit-scrollbar-thumb {
                background:#030E3A ; 
                border-radius: 10px;
              }

            .card{
                flex: 0 0 auto;
                background-color: white;
                // background-color: #f8f9fa;
                &:hover{
                    background-color: $primary-hover;
                }
            }
            .card{
                margin-left: 8px;
                margin-right: 8px;
            }
            @media screen and (max-width :540px){
                .card{
                    width: 100%;
                }

            }
        }
        .status-update-room{
            div {
                span{
                    cursor: pointer;
                }
            }
        }
        .disp-table-rooms{
            table{
                thead{
                    th{
                        cursor: pointer;
                    }
                }
                tbody{
                    .show-updated-circle{
                        height: 8px;
                        width: 8px;
                        background-color:#1877F2;
                        border-radius: 50%;
                        display: inline-block;
                    }
                }
            }
        }
    }
}