//
// team.scss
//
.teamsTab{
    @media only screen and (min-width: 1681px) {
        max-width: 1680px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
}
.status-Active, .status-Away, .status-InActive, .status-InProgress, .status-Archived, .status-not-available {
    height: 12px;
    width: 12px;
    border-radius: 2px;
    display: inline-block;
}
.status-Active {
    background-color: #13BA9C;
}

.status-Away, .status-InActive {
    background-color: #F84367;
}
.status-InProgress, .status-Archived{
    background-color: #FFA010;
}
.status-not-available{
    background-color: #FCCD13;
}

.profile-user-image {
    bottom: -50%;
    left: 50%;
    transform: translate(-50%, 0);
}