.global-dash-head {
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid;
  background: #fff;
  .header-item-position {
      display: flex;
      flex: 1 1 0%;
      min-width: 0px;
      height: 100%;
      .item {
          display: flex;
          padding: 0px 30px;
          -webkit-box-align: center;
          align-items: center;
          cursor: pointer;
          color: rgb(43, 29, 56);
          transition: color 0.1s ease 0s;
          user-select: none;
          height: 100%;
          width: 100%;
          ol {
            margin: 0;
          }
      }
    }  
}



