//
// breadcrumb.scss
//
// Breadcrumb item arrow
.breadcrumb-item {
    // font-size: 15px !important;
    &.active {
        color: $primary;
    }
    > a {
        color: $secondary;
        // opacity: .61;
        // &:hover {
            //     opacity: 1;
            // }
        }
        + .breadcrumb-item {
        cursor: pointer;
        &::before {
            font-weight: 900;
            color: black;
            opacity: 1;
            height: 4.75px;
            width: 24.5px;
            padding-top: 4px;
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
            // background-image: ;
        }
    }
}

.breadcrumb {
    padding: 0;
    background-color: transparent;
    .breadcrumb-item:not(.active) {
        cursor: default;
    }
}

