.btn-toggle-container {
    p {
        margin-bottom: 0;

        &.active {
            font-weight: 700;
        }
    }
}
.btn-toggle-container > p:after {
    content: attr(data-text);
    display: block;
    font-weight: 700;
    height: 0.01px;
    opacity: 0;
}
.btn-toggle {
    min-width: 48px!important;
    background-color: $white;
    margin: 0 16px;
    padding: 0;
    position: relative;
    height: 24px;
    border-radius: 1.5rem;
    border: none;
    color: $gray-400;
    outline: none;
    box-shadow: 0 0 0 0.05rem $blue!important;
}
.btn-toggle > .handle {
    position: absolute;
    top: 0.13rem;
    left: 0.1875rem;
    width: 20px;
    height: 20px;
    border-radius: 1.125rem;
    background: $blue;
    transition: left 0.25s;
}
.btn-toggle.active > .handle {
    left: 1.6rem;
    transition: left 0.25s;
}
