.query-version-display {
    padding: 12px 24px;
    height: 100%;
    max-height: 100%;
    caret-color: transparent;
    overflow-y: auto;
}

.query-version-container {
    height: calc(100% - 50px);
    overflow: hidden;
}

.compare-version-dropdown {
    width: 300px;
}

.monaco-diff-editor {
    .original {
        .slider, .decorationsOverviewRuler {
            display: none !important;
        }
    }
    .modified {
        border-left: 1px solid #e2e7f1 !important;
    }
}

.version-header {
    background-color: #F8F8F8;
}
