
.darkmode--activated{
    $primary: #3D94FF !important;
    $secondary: #3D94FF !important;
    $link-color: #3D94FF !important;
    $success: #13BA9C !important;
    $danger: #F84367 !important;
    $warning: #FCCD13 !important;
    $background: #1B1B31 !important ;
    $background2: #20233C !important;
    $text-color: #C5D0FC !important;
    $disabled-text-color: #53538C !important;
    $buttonHover: #6BAEFF !important;
    $cardAndTableHover: #272949 !important;
    $border-color: #2B2D50 !important;
    $table-background: #232643 !important;
    $transparent: transparent !important;
    // mix-blend-mode: normal;
    background: $background ;
    .text-primary{
        color: $primary;
    }
    .text-secondary{
        color: $secondary;
    }
    .text-success{
        color: $success;
    }
    .text-danger{
        color: $danger;
    }
    .text-warning{
        color: $warning;
    }
    .content-page{

    background: $background ;
    // color: white;
    }
    #wrapper{
        background-color: transparent;
    }
    .navbar-custom{
        background-color: $background2;
    }
    h1,
    h2,
    h3,
    h5,
    h4,
    p,
    strong,
    b,
    h6,
    small,
    span,
    label
    {
        color: $text-color
    }
    ::placeholder{
        color: $disabled-text-color;
    }
    a{
        color: $primary;
    }

    .bg-light{
        background-color: $background;
    }
    #settings-sidebar{
        .bg-secondary{
            background-color: $border-color;
        }
    }
    .bg-white{
        background-color: $background2;
    }
    .bg-main{
        background-color: $background;
    }
    .bg-lightgray{
        background-color: $background2;
    }
    .bg-transparent{
        background-color: $transparent;
    }
    .row{
        color: $text-color;
    }
    text.highcharts-title{
        fill: $text-color;
    }
    rect {
        &.highcharts-plot-border{
            stroke: $border-color;
        }
        &.highcharts-background{
            fill: $transparent;
        }
    }
    .highcharts-legend-item.highcharts-bar-series{
        text{
            fill: $text-color;
        }
    }
    .highcharts-grid.highcharts-xaxis-grid{
        path{
            stroke: $border-color;
        }
    }
    .highcharts-grid.highcharts-yaxis-grid{
        path{
            stroke: $border-color;
        }
    }
    .highcharts-map-series{
        path{
            fill: $cardAndTableHover;
            stroke: $border-color;
        }
    }
    .vl{
        border-color: $border-color;
    }
    .inner-box{
        border-color: $border-color;
    }
    .light{
        border-color: $border-color;
        .circle{
            background-color: $disabled-text-color;
        }
    }
    .progress{
        background-color: $cardAndTableHover;
        .progress-bar.bg-secondary{
            background-color: $secondary;
        }

    }

    .breadcrumb-item + .breadcrumb-item::before {
    content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' stroke='white' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    mix-blend-mode: screen;

    }
    .breadcrumb-item{
        color: $text-color;
    }
    .border,.border-right,.border-left,.border-top,.border-bottom{
        border-color: $border-color;
    }
    hr{
        border-color: $border-color;
    }
    .dropdown-divider{
        border-color: $border-color;
        border-top:1px solid $border-color;
    }
    .form-control{
        background-color: $background2 ;
        color: $text-color;
        border-color: $border-color;
    }
    .threat-item.odd {
        background-color: #061968 !important;
    }
    .custom-button:hover, .custom-button.active{
        background-color: #061968;
    }
    // icon
    i{
        color: $primary;
    }
    // tag
    tag,.tag:not(.token){
        background-color: $background2;
        color: $text-color;
        .btn{
            svg{
                color: $primary;
            }
        }
    }

    .btn{
        // background-color: transparent !important;
        color: #1B1B31;
    }
    .btn-primary{
        background-color: $primary;
    }
    .input-group[border='none']{
        border-color: $border-color;
    }
    .input-group[border='active']{
        border-color: $primary;
        .input-group-text{
            border-color: $primary;
        }
    }
    .input-group-text{
        background-color: $background2;
        border-color: $border-color;
    }
    input[type='radio']:checked:before {
        background: $primary;
    }
    .account-demo{
        background-color: $transparent;
    }
    svg.settings{
        stroke: $text-color;
    }
    .regraph-container{
        background-color: $transparent;
    }
    .left-side-menu {
        background-color: $background2;
        &.scrollbar::-webkit-scrollbar-thumb{
            background: #061968;
            outline: none;
        }
    }
    .pagination .page-item .page-link:hover{
        background-color: $cardAndTableHover;
    }
    .pagination .page-item .page-link{
        color: $text-color
    }
    svg{
        color: $text-color
    }
    #sidebar-menu > ul > li > a {
        color: $text-color;
        &.active{
            background-color: #030E3A;
        }
        &:hover{
        background-color: $primary;
        // color: white;
        svg{
            color: $text-color
        }
        }
    }
    .card{
        background-color: $background2;
    }
    .card:hover{
        background-color: $cardAndTableHover;
    }
    .table {
        th,tbody tr{
        background-color: $background2;
        color: $text-color;
        border-color: $border-color;
    }
    td{
        border-color: $border-color;
        color: $text-color;
    }
    tr:nth-child(odd){
        background-color: $table-background
    }
    // inputgroup border color
    tr:hover{
        background-color: $cardAndTableHover;
        a {
            color: $buttonHover;
            }
        }
    }
    .multi-tabs{
        .selected-tab{
            color: $primary;
            h4{
            color: $primary;
            }
        }
    }
    .custom-control-label::before{
        background-color: transparent;
    }
    .custom-control-input-big.custom-checkbox .custom-control-label{
        color: $text-color;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $primary;
    }
    .custom-control-input-big.custom-checkbox .custom-control-label::before{
        border-color: $primary;
    }
    .custom-control-input-big.custom-checkbox .custom-control-label:hover::before{
        box-shadow: 0px 0px 5px 7px $cardAndTableHover;
    }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231B1B31' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    .situation-room .investigation-item:hover{
        background-color: #061968;
    }
    .investigation-in-progress{
        background-color: $primary;
    }
    .btn-secondary {
        background-color: transparent;
        color: $primary;
        &:hover {
            background-color: $primary;
            color: $background
        }
        &:disabled {
            color: $gray-500;
        }
    }
    .btn-secondary:not(.border-0) {
        border: 1px solid $primary;
        &:hover {
            border-color: $primary !important;
        }
    }
    .btn-link{
        color: $primary;
    }
    .list-group-item-action{
        color: $text-color;
    }
    .input-group {
        .input-group-prepend{
            span{
                background-color: transparent;
            }
            .input-group-text{
                background-color: transparent;
            }
        }
    }
    .dropdown-menu{
        background-color: $background2;
    }
    .dropdown button{
        background-color: $background2;
        color: $text-color;
    }
    .dropdown-item{
        color: $text-color;
        background-color: $background2;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.6);
    }
    .dropdown-item.active, .dropdown-item:active, .dropdown-item:hover{
        background-color: $cardAndTableHover;
    }
    .dropdown-menu .custom-control-input-big.custom-checkbox:hover{
        background-color: $primary;
    }
    .bg-hover{
        background-color: $background2;
    }
    input.form-control{
        color: $text-color;
    }
    .fullscreen{
        mix-blend-mode: screen;
    }
    .modal-content{
        .modal-header{
            border-color: $border-color;
        }
        .modal-footer{
            border-color: $border-color;
        }
        background-color: $background2;
    }
    .credibility input[type="file"] {
        color: $primary;
        background-color: $cardAndTableHover;
    }
    .threats .total-bar{
        background-color: $background2;
        color: $text-color;
    }
  .engagement-box{
      background-color: #061968;
  }
  .control{
      select{
          background-color: #061851;
      }
      select.round{
          background-image: linear-gradient(
            45deg, transparent 50%, gray 50%), linear-gradient(
            135deg, gray 50%, transparent 50%), radial-gradient(#061851 70%, transparent 72%);;
      }
  }
  button.bg-light:hover{
      background-color: #061968 !important;
  }
  .trash{
      background-color: transparent;
      border-color: $border-color;
      color: $text-color;
  }
    .input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
        background-color: $transparent;
        box-shadow: $transparent;
        -webkit-box-shadow: $transparent;
    }
    input.form-control {
        color: #C5D0FC !important;
    }
    .input-group input.form-control {
        height: 42px;
    }
    .input-group[border='active'] input {
        border: none;
    }
    .form-control {
        background-color: #20233C !important;
        color: #C5D0FC !important;
        border-color: #2B2D50 !important;
    }
    .input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
    }
  .nav-tabs li a.active {
      border-bottom-color: $primary;
  }
  .navbar-dark .navbar-nav .show > .nav-link .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link {
      &.active{
          border-bottom-color: $primary;
          color: $primary;
      }
      color: $text-color;

  }
    .tooltip-inner{
        background-color: $background2;
        color: $text-color;
        border-color: $border-color;
    }
    .traffic-signal-small{
        .bg-gray{
            background-color: #53538C !important;
        }
    }
    .overview-box.border-primary, .react-resizable-handle.border-primary {
        border: 1px solid $primary;
    }
}