.custom-carousel-indicators {
    position: relative;
    padding: 0;
    margin: 0 0 0 -4px;
    justify-content: start;
    li {
        border-radius: 50%;
    }
}

.carousel-inner {
    overflow: visible;
    .carousel-item {
        display: block;
        img {
            margin-left: -20px;
            max-width: 100%;
        }
    }
}
