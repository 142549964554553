//
// type.scss
//

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 8px 0;
    font-family: $font-family-secondary;
    // color: $secondary;
    color: $gray-400;
    font-weight: $font-weight-semibold;
}
.h1,
h1 {
    line-height: 34px;
    font-weight: 700;
}
.h2,
h2 {
    line-height: 28px;
    font-weight: 700;
}
.h3,
h3 {
    line-height: 26px;
    font-weight: 700;
}
h4{
    font-weight: 700;
}
p{
    line-height: 24px;
}
small{
    font-size: 14px;
    line-height: 20px;
}
.h1-dark,
.h2-dark,
.h3-dark,
.h4-dark,
.h5-dark,
.h6-dark {
    margin: 10px 0;
    font-family: $font-family-secondary;
    color: $gray-100;
    font-weight: $font-weight-semibold;
}

p {
    line-height: 1.6;
    color: $gray-400;
}
b, strong{
    color: $gray-400;
}
h5{
    font-weight: 700;
}

// Font sizes (Custom)

@for $i from 5 through 40 {
    .font-size-#{$i} {
        font-size: #{$i}px !important;
    }
}

// text utilies

.text-primary[role="button"]:hover {
    color: #004dad !important;
}

.hover-white:hover, .hover-white:active, .hover-white:focus {
    color: #ffffff !important;
}
