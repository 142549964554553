.show-circle {
    height: 12px;
    width: 12px;
    background-color:$success;
    border-radius: 2px;
    display: inline-block;
}

.show-circle-orange{
    background-color: orange;
}

.alert-container{
    width: fit-content;
}

.factcheck-title{
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
}