.progress-bar {
    background-color: #006FF9 !important;
    height: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.progress {
    background-color: #E5E6EB !important;
    height: 8px;
}
