//
// card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow-sm;
    border-radius: 0.25rem;
    &.shadow-lg {
        box-shadow: $box-shadow-lg;
    }
    &.content-view {
        word-wrap: normal;
        min-width: 8rem;
        max-width: 8rem;
        min-height: 4rem;
        &.new-content-view {
            box-shadow: none;
            border-radius: 0.25rem;
            border: solid 1px #E5E6EB;
        }
        &.new-content-view:hover {
            background: #E6F1FE;
        }
        &.keywords {
            min-width: 10rem;
            max-width: 10rem;
        }
        &.threats {
            min-width: 4rem;
            max-width: 20rem;
        }
        &.non-clickable {
            cursor: initial;
        }
    }
}

.card-text {
    &.title {
        font-size: 0.9rem;
    }
}
