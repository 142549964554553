.circle-small {
    width: 11px;
    height: 11px;
    background: #E5E6EB;
    border-radius: 50%;
}

.circle-small-blue {
    width: 11px;
    height: 11px;
    background: #4367F8;
    border-radius: 50%;
}

.circle-small-purple {
    width: 11px;
    height: 11px;
    background: #D945D0;
    border-radius: 50%;
}

.circle-small-green {
    width: 11px;
    height: 11px;
    background: #00C0C7;
    border-radius: 50%;
}
.circle-small-orange {
    width: 11px;
    height: 11px;
    background: #FF6C4C;
    border-radius: 50%;
}

.square-small {
    min-width: 14px;
    width: 14px;
    height: 14px;
    background: #E5E6EB;
    border-radius: 2px;
}

.square-small-blue {
    min-width: 14px;
    width: 14px;
    height: 14px;
    background: #4367F8;
    border-radius: 2px;
}

.square-small-purple{
    min-width: 14px;
    width: 14px;
    height: 14px;
    background: #D945D0;
    border-radius: 2px;
}

.square-small-green{
    min-width: 14px;
    width: 14px;
    height: 14px;
    background: #00C0C7;
    border-radius: 2px;
}

.square-small-orange{
    min-width: 14px;
    width: 14px;
    height: 14px;
    background: #FF6C4C;
    border-radius: 2px;
}
.square-small-yellow{
    min-width: 14px;
    width: 14px;
    height: 14px;
    background: #FFBC00;
    border-radius: 2px;
}

.highcharts-credits {
    display: none;
}

.help-circle {
    line, path {
        stroke: $white;
    }
    circle {
        stroke: $primary;
    }
    fill: $primary;
    &.open {
        fill: $primary-dark;
        circle {
            stroke: $primary-dark;
        }
    }
}