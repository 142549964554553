// 
// pagination.scss
//

.pagination{
  .page-item.disabled {
    .page-link{
      color: $gray-300;
      &:hover{
        background-color: transparent;
      }
      background-color: transparent;
  }
  }
  .page-item.active {
    .page-link{
      color: white;
      &:hover{
        background-color: $primary;
      }
      background-color: $primary;
  }
  }
  .page-item{
    .page-link{
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: transparent;
      color: $gray-400;
     
        &:hover{
          background-color: $primary-hover;
        }
    }
    button[aria-label='Next'].page-link{
      padding-top: 0;
      margin-top: -4px;
      span{
        font-size: xx-large;
      }
    }
    button[aria-label='Last'].page-link{
      padding-top: 0;
      margin-top: -4px;
      span{
        font-size: xx-large;
      }
    }
    button[aria-label='First'].page-link{
      padding-top: 0;
      margin-top: -4px;
      span{
        font-size: xx-large;
      }
    }
    button[aria-label='Previous'].page-link{
      padding-top: 0;
      margin-top: -4px;
      span{
        font-size: xx-large;
      }
    }
  }
}
