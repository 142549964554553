//
// buttons.scss
//
// button :hover {
//     box-shadow: 0px 0px 5px 7px $primary-hover !important;
// }
.btn {
    min-width: 88px;
    color: $white;
    padding: 8px 16px 8px 16px;
    border-radius: 0.25rem;
    &:focus {
        box-shadow: none;
    }

    .icon {
        svg {
            width: 1rem;
            height: 1rem;
            margin-left: 0.1rem;
            margin-top: -2px;
        }
    }
}

.btn-primary {
    color: $white;
    background: $primary;
    border-color: $primary;
    font-weight: 500;
    &.btn-inverse {
        color: $primary;
        background: $white;
        &:hover, &:active {
            color: $primary !important;
            background-color: $gray-300 !important;
        }
    }
    &:hover {
        background-color: #025AC7 !important;
    }
    &:focus {
        border-color: $primary !important;
        box-shadow: 0px 0px 0px 0px $primary-hover !important;      
    }
}
.btn-danger:hover {
    background-color: $danger-hover;
}
.btn-danger:focus{
    border-color: $danger !important;
    box-shadow: 0px 0px 0px 0px $danger-hover !important;;
}
.btn-success {
    color: $white;
    background: $light-green;
    border-color: $light-green;
    font-weight: 500;
    &.btn-inverse {
        color: $light-green;
        background: $white;
        &:hover, &:active {
            color: $light-green !important;
            background-color: $gray-300 !important;
        }
    }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    border: transparent !important;
    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent ;
    }
    // &:hover {
    //     background-color: transparent !important;
    // }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
}
.btn-link-danger {
    font-weight: $font-weight-normal;
    color: $link-danger-color;
    background-color: transparent;
    border: transparent !important;
    &:hover {
        color: $link-danger-hover-color;
        text-decoration: $link-danger-hover-decoration;
        background-color: transparent;
        border-color: transparent ;
    }
    &:focus,
    &.focus {
        text-decoration: $link-danger-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
}
.icon-button {
    display: flex;
    align-items: center;
    &:hover {
        text-decoration: none;
    }
    color: $secondary-alt;
    text-decoration: none;
    align-items: center;
    svg{
        color: $primary;
    }
}
.btn[disabled] {
    background-color: $gray-300;
    border-color: $gray-300 !important;
    color: $gray-500;
    &:hover {
        background-color: $gray-300 !important;
    }
}

.btn[state = loading] {
    box-shadow: 0px 0px 0px 0px transparent !important;
    background-color: $gray-300 !important;
    border-color: $gray-300 !important;
    color: transparent;
    &:hover {
        :active{
            background-color: $gray-300;
            border-color: $gray-300 !important;
            color: transparent !important;
        }
        background-color: $gray-300 !important;
    }
    position: relative;
    
}
.btn[state = loading]:active{
    color: transparent !important;
    border-color: $gray-300 !important;
}
.btn[state = loading]:hover{
    color: transparent !important;
    border-color: $gray-300 !important;
}
.btn[state = loading]::after {
    content: "";
    position: absolute;
    width: 16.24px;
    height: 16.24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: $gray-600;
    border-right-color: $gray-600;
    border-left-color: $gray-600;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  .rotate {
    animation: button-loading-spinner 1.5s ease infinite;
  }
  
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
  
    to {
      transform: rotate(1turn);
    }
  }
  
  // for secondary button
.btn-secondary {
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;
    &:hover {
        // border: 1px solid $primary;
        border-color: $primary !important;
        background-color: $primary;
        color: white
    }
    &:disabled {
        color: $gray-500;
    }
}
.btn[state = loading].btn-link {
    border-color: transparent;
    background-color: transparent !important;
} 
.dropdown-toggle::after {
    display: inline-block;
    position: absolute;
    right: 5%;
    top: 20px;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: $primary;
}

//
// Alternate buttons
//
.filter-btn-arrow{
    transition: all .25s ease-in-out;
}
.transform-btn-icon{
    transform: rotate(-180deg);
}

.btn-has-filters {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: -3px;
        right: -4px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $primary;
    }
}

.icon-delete-button {
    height: 24px;
    width: 24px;
}

.label-btn{
    color: #030E3A !important;
    border: 0;
    background-color: transparent !important;
}
.label-icon-btn{
    background-color: transparent;
    border: 0;
}
.non-label-btn {
    color: #006FF9 !important;
    border: 0;
    background-color: transparent !important;
}

.input-addon-button {
    color: $primary !important;
    user-select: none;
    cursor: pointer;
    border-radius: 0 !important;
    &:hover {
        color: $white !important;
        background-color: $primary !important;
    }
}