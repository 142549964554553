//
// loading.scss
//

.loading-container {
    .loading-bg {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 10px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        p{
            margin: 0;
                color: #dbdbdb;
                font-weight: 700;
                text-align: center;
        }
    }
    .loading-square-keyword-mentions{
        width: 100%;
        height: 170px;
        align-items: center;
        justify-content: center;
        display: flex;
        p{
            margin: 0;
                color: #dbdbdb;
                font-weight: 700;
                text-align: center;
        }
    }
}

@keyframes shine {
    to {
      background-position-x: -200%;
    }
}
