.DraftEditor-root {
    height: 100%!important;
    width: 100%;
    position: relative!important;
    right: 0;
    top: 0;
    @media (max-width: 992px) {
        min-height: 265px;
        position: relative!important;
    }
}

.public-DraftEditor-content {
    min-height: 276px;
}

mark {
    color: #030E3A!important;
}

.public-DraftStyleDefault-ltr {
    text-align: left;
    text-align-last: left;

    & mark {
        padding: 0;
        background-color: rgba(248, 67, 103, 0.1);
        border: 1px solid #F84367;
        border-radius: 0.3rem;
    }

    & mark.selected {
        background-color: rgba($warning, 0.3);
        border: 1px solid rgba($warning, 0.3);
    }

    & mark.operator {
        color: #00C0C7!important;
        background-color: transparent;
        border: none;
    }

    & mark.quotes {
        color: #FF6C4C!important;
        background-color: transparent;
        border: none;
    }

}

.error-focus {
    background-color: rgba(248, 67, 103, 0.6)!important;
}

div.hover-row.status-bar {
    display: none !important;
    z-index: 1;
}

.find-widget, .monaco-findInput {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;
    border: 1px solid #E5E6EB;
    border-radius: 4px !important;
    margin-inline: 12px;
    width: 252px !important;
    height: 40px;
}
.scroll-decoration {
    box-shadow: none !important;
}

.squiggly-error {
    border: 1px solid #F84367 !important;
    border-radius: 0.3rem;
    background: rgba(248, 67, 103, 0.1) !important;
}

.squiggly-warning {
    border: 1px solid rgba(248, 67, 103, 0.6) !important;
    border-radius: 0.3rem;
    background: rgba(248, 67, 103, 0.6) !important;
}

.squiggly-info {
    border: 1px solid $warning !important;
    border-radius: 0.3rem;
    background: rgba($warning-light, 0.6) !important;
}

.hoverHighlight {
    background-color: transparent !important;
}

.monaco-editor {
    width: 100% !important;
}

.overflow-guard {
    width: 100% !important;
}

.monaco-editor .scrollbar {
    width: 8px !important;
    background-color: $lightgray;
}

.decorationsOverviewRuler {
    width: 8px !important;
    z-index: 1 !important;
}
.settings-noBorder {
    .decorationsOverviewRuler, 
    .monaco-scrollable-element>.invisible.fade,
    .monaco-scrollable-element>.visible,
    .slider {
        display: none !important;
    }    
}

.slider {
    width: 8px !important;
    background-color: $blue !important;
}

.monaco-editor {
    .codicon-symbol-keyword::before {
        content: '' !important;    
    }
    .find-widget{
        .codicon-find-collapsed,
        .codicon-find-expanded,
        .codicon-find-previous-match,
        .codicon-find-next-match,
        .codicon-find-selection,
        .codicon-widget-close
         {
            font-weight: 600;
        }
    }
}
.monaco-editor {
    .find-widget{
        right: 25px !important;
        min-height: 80px !important;
        height: auto !important;
        padding: 16px !important;
        max-width: 599px !important;
        width: auto !important;

        .codicon-whole-word,
        .codicon-regex,
        .codicon-preserve-case,
        .codicon-find-replace:before,
        .codicon-find-replace-all:before,
        .codicon-case-sensitive
         {
            display: none !important;
        }
        .codicon-find-replace {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25 25" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>');
            height: 20px;
            width: 20px;
            margin-top: 12px;
        }
        .codicon-find-replace.disabled, .codicon-find-replace:hover.disabled {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25 25" fill="none" stroke="%23B0B4C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>');
            }
        .codicon-find-replace:hover{
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25 25" fill="none" stroke="%23006FF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>');
        }
        .codicon-find-replace-all {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-right"><polyline points="13 17 18 12 13 7"/><polyline points="6 17 11 12 6 7"/></svg>');
            height: 24px;
            width: 24px;
            margin-top: 12px;
        } 
        .codicon-find-replace-all.disabled, .codicon-find-replace-all:hover.disabled {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none" stroke="%23B0B4C5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-right"><polyline points="13 17 18 12 13 7"/><polyline points="6 17 11 12 6 7"/></svg>');
        }
        .codicon-find-replace-all:hover {
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none" stroke="%23006FF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-right"><polyline points="13 17 18 12 13 7"/><polyline points="6 17 11 12 6 7"/></svg>');
        }
        .monaco-findInput>.controls {
            display: flex !important;
            flex-direction: row-reverse;
            margin-right: 0px;
            position: fixed;
            top: calc(100% - 55px);
            right: calc(100% - 295px);
        }
        .codicon-find-selection, .button {
            height: 24px !important;
            width: 24px !important;
            color: $secondary-alt !important;
            font-size: 20px !important;
        }
        .button.toggle {
            left: auto !important;
            height: 100% !important;
            width: 18px !important;
        }
        .monaco-findInput .input {
            font-size: 16px !important;
            padding: 8px 16px !important;
            height: 100% !important;
        }
        .monaco-findInput .monaco-inputbox {
            height: 100% !important;
        }
        .monaco-findInput .monaco-inputbox.synthetic-focus {
            outline-color: $blue !important;
        }
        .matchesCount {
            font-size: 16px !important;
        }
        .find-part, .replace-part {
            .button:not(.disabled):hover, 
            .codicon-find-selection:hover,
            .monaco-custom-toggle:hover {
                color: $blue !important;
                background-color: transparent !important;
            }
            .disabled {
                color: #B0B4C5 !important;
            }
        } 
        .button.button:not(.disabled):hover{
            color: $blue !important;
            background-color: transparent !important;
        }  
    }
    .find-widget.no-results .matchesCount {
        color: $secondary-alt !important;
    }
    .find-widget>.find-part {
        margin-bottom: 8px !important;
    }
}

.overlayWidgets {
    .editor-widget {
        height: 50px !important;
    }
    .replaceToggled {
        height: 130px !important;
    }
}

.editor-container {
    position: relative;
    height: 100%;
 }
  
.editor-placeholder {
    position: absolute;
    top: 15px;
    left: 55px;
    color: #888;
    pointer-events: none;
    z-index: 1;
}