.fixed-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
}

.content-container {
    height: calc(100vh - 75px);
    overflow-y: auto;
    overflow-x: hidden;
}

.left-panel {
    height: calc(100% - 50px);
    min-width: 40%;
    width: calc(100% - 36px);
    overflow-y: auto;
    position: absolute;
    padding-right: 24px;
    padding-bottom: 32px;
    padding-top: 32px;
}

.right-panel {
    height: calc(100% - 50px);
    width: calc(100% - 40px);
    overflow-y: auto;
    position: absolute;
    padding-right: 32px;
    padding-bottom: 24px;
}

.keyword-input {
    min-height: 150px;
    height: 15vh;
}

.keyword-textarea {
    max-height: 70%;
    overflow-y: auto;
}

.split-view {
    div[data-type="Resizer"] {
        height: 130px;
        margin: auto;
        margin-right: 6px;
        background-color: #B0B4C5;
        width: 4px;
        opacity: 1;
        border-radius: 10px;
        border: none;
        border-left:1px solid #B0B4C5;
        border-right:1px solid #B0B4C5;
        transition: none;
        cursor: url("data:image/svg+xml,%3Csvg width='30' height='16' viewBox='0 0 30 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 8H1' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 15L1 8L8 1' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 8H29' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22 1L29 8L22 15' stroke='%23006FF9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") 16 16, auto;
    }
  
    div[data-type="Resizer"]:hover{
        background-color: $primary;
        border-left:1px solid $primary;
        border-right:1px solid $primary;
    }
    
    &.hide-resizer {
        div[data-type="Resizer"] {
            display: none;
        }
    }
}


.versions-row {
    height: calc(100vh - 135px);
    padding-bottom: 70px;
}
.input-width{
    width: 40%;
    @media (max-width:1272px) {
        width: 100%;
    }
}
.banner-width{
    width:39%;
    @media (max-width:1272px) {
        width: 100%;
    } 
}
.input-width-date{
    width: 40%;
    @media (max-width:1272px) {
        width: 100%;
        padding-right: 16px;
    }
}
.keywords-container{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.right-panel-border{
    border-left:1px solid #E5E6EB;
    z-index: 12;
}
.left-container{
    min-width: 700px;
}