// 
// modal.scss
//


// Title text within header
.modal-title, .modal-body > h2 {
    margin-top: 0;
}

// Full modal
.modal-full {
    width: 92%;
    max-width: none;
}

.max-width {
    max-width: 420px;
}

.modal-content{
    div.modal-header{
        margin: 0;
        padding: 24px !important;
    }
    div.modal-body{
        margin: 0px;
        padding: 24px !important;
    }
    div.modal-footer{
        margin: 0;
        padding: 24px !important;
    }
}