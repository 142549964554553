//
// no-data-available.scss
//

.no-data-available {
    h3 {
        font-weight: 600;
        font-size: 1rem;
    }

    img {
        filter: brightness(1.08)
    }
}
