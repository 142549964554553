ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #B0B4C5;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 24px 0;
    padding-left: 16px;
}
ul.timeline > li:before {
    content: ' ';
    background: #B0B4C5;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #B0B4C5;
    left: 23px;
    width: 14px;
    height: 14px;
    z-index: 400;
}