//
// topbar.scss
//

.navbar-custom {
    padding: 0 0 0 0;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    background-color: $secondary-alt;

    .topnav-menu {
        > li {
            float: left;
        }
        p {
            box-shadow: 1px 0.05rem 0.01rem rgba(255, 255, 255, 0.15);
        }
        .nav-link {
            padding: 0 30px;
            min-width: 32px;
            display: block;
            line-height: $topbar-height;
            text-align: center;
            max-height: $topbar-height;
            cursor: pointer;
            font-weight: 600;
            svg {
                color: $menu-item;
                fill: rgba($menu-item, 0.12);
            }
        }
    }
    .dropdown.show {
        .nav-link {
            background-color: rgba($white, 0.05);
        }
    }

    .navbar-brand {
        width: $leftbar-width;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 0.05rem 0.01rem rgba(255, 255, 255, 0.15);
        min-height: $topbar-height;
        @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
            width: 320px;
        }
    }
    .navbar-toggler{
        @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        // text-align: center;
    }
}

.text-logo {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    color: $gray-100;
}

/* Notification */
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.notification-list {
    margin-left: 0;

    .notify-item {
        padding: 12px 20px;

        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            font-size: 16px;
            line-height: 36px;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            color: $white;
        }
    }
    .profile-dropdown-items {
        .notify-item {
            padding: $dropdown-item-padding-y $dropdown-item-padding-x;
            cursor: pointer;
        }
    }
}

.profile-dropdown-items {
    width: 170px;
    i {
        vertical-align: middle;
        margin-right: 5px;
    }
}

.nav-user {
    padding: 0 12px !important;
    img {
        height: 32px;
        width: 32px;
    }
}

.noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 12px;
    width: 18px;
    height: 18px;
    background-color: $danger;
    border: 2px solid $danger;
    border-radius: 50%;
    box-shadow: $box-shadow-lg;
    text-align: center;
    font-size: 10px;
    color: #ffffff;
}