.tag:not(.token){
    min-height: 36px;
    background-color: $primary-hover;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    align-items: center;
    line-height: 18px;
    color: #030E3A;
    .btn{
        min-width: 28px;
        height: 18px;
        display: flex;
        padding: 0px 0px 0px 8px;
        &.tag-plus{
        padding: 6px 8px 8px 0px !important;
        }
        svg{
            color: $primary;
            height: 16px;
            width: 16px;
        }
    }
    &:hover:not(.no-hover), &.selected{
        border: 1px solid $primary;
    }
    &.selected-label{
        background-color:$gray-400 !important ;
        color:#FFFFFF ;
    }
    &.small{
        font-size: 14px;
        min-height: 0;
        .btn{
            display: flex;
            align-items: center;
            svg{
                height: 14px !important;
                width: 14px !important;
            }
        }
    }
    &.new-content-view{
        background-color: #E6F1FE;
        border: 0;
        padding: 8px 12px;
    }
    &[color='danger']{
        background-color: #FFEDF0;
        &.duplicate{
            border: 1px solid $danger;
        }
        &:hover{
            border: 1px solid $danger;
        }
        .btn svg{
            color: $danger
        }
    }
    &[color='success']{
        background-color: #E8F9F6;
        &:hover{
            border: 1px solid $success;
        }
          .btn svg{
            color: $success
        }
    }
    &[color='warning']{
        background-color: #FFFAE8;
        &:hover{
            border: 1px solid $warning;
        }
          .btn svg{
            color: $warning
        }
    }
    &[color='dark']{
        background-color: $gray-400;
        color: white;
        &:hover{
            border: 1px solid $gray-400;
        }
          .btn svg{
            color: white
        }
    }
    &.small {
        min-height: 28px;
    }
}
.tag-selected{
    background-color:$primary !important ;
    color:#FFFFFF ;
}
.settings-tag{
    border: transparent !important;
}