//
// dropdown.scss
//

.dropdown-menu {
    // left:auto !important;
    box-shadow: 0 0.5rem 1rem rgba(3, 14, 58, 0.15);
    // box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    font-size: $font-size-base;
    position: absolute;
    z-index: 99;
    padding: 0px;
    border-radius: 4px;
    &.show {
        top: 100% !important;
        width: max-content;
        min-width: 200px;
    }
    .custom-control-filter{
        white-space: nowrap;
        padding-left: 16px;
        input {
            position: unset;
            &::after {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
    } 
    .custom-control-input-big.custom-checkbox {
        white-space: nowrap;
        padding-left: 32px;
        input {
            position: unset;
            &::after {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
    }
}

.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=left]{
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
}

.menu-hide {
    visibility: hidden;
}

.dropdown-item.active, .dropdown-item:hover, .dropdown-item:hover .menu-hide{
    background-color: $primary-hover !important;
    visibility: visible;

}

.dropdown-header:hover, .dropdown-header.active{
    background-color: #FFFFFF !important;
}

.dropdown-item.active
.dropdown-item {
    background-color: $lightgray;
    border-radius: 2px;
}

.dropdown{
    min-width: 7rem;
    div{
        &.form-control{
            span{
            min-width: 5px;
            }
        }
    }
}
.dropdown-divider{
    margin: 0px;
}

.delete-label-dropdown{
    .btn-secondary{
        background-color: transparent;
    }
    .btn-secondary:hover{
        background-color: transparent !important;
    }
    
}

.notification-dropdown.dropdown-menu.show{
    max-width: 460px;
}

.noti-page{
    background-color: #f8f8f8;
}

.notification-dropdown, .noti-page{
    .dropdown-menu.show{
        min-width: fit-content;
    }
}

.unread {
    display: inline-block;
    width: 9px;
    height: 9px;
    background-color: $danger;
    border-radius: 50%;
    box-shadow: $box-shadow-lg;
}

.maxw-390 {
    max-width: 390px;
}

.noti-avatar {
    background-color: $primary;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noti-container {
    background-color: #FFFFFF;

}

.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
}